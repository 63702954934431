<template>
  <v-app :style="colorVapp">
    <div>
      <onebox_toolbar></onebox_toolbar>
      <v-content>
        <v-overlay :value="processloader" z-index="2" color="black" dark>
          <v-progress-circular
            indeterminate
            size="64"
            color="white"
          ></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>
        <v-card class="elevation-0">
          <!-- Header Page -->
          <v-list nav :color="color.BG" class="px-4" v-if="resolutionScreen < 500">
            <v-card rounded="xl" class="elevation-0 d-flex align-center" height="40">
              <v-list-item class="text-left">
                <v-list-item-avatar class="text-center ma-0">
                  <v-icon dark size="24" :color="color.theme">mdi-folder-clock</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="pl-3 pt-1 pb-1">
                    <span :style="headerPage">{{ $t("fileexpiredall.fileexpired") }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-list>
          <v-list nav :color="color.BG" v-else>
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">mdi-folder-clock</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span :style="headerPage"
                    >&nbsp;{{ $t("fileexpiredall.fileexpired") }}</span
                  >
                </v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-action class="pa-0 text-center">
              <v-btn
                @click="resetFile(),changeLocalStorage()"
                outlined
                fab
                small
                :color="color.theme"
                v-if="resolutionScreen< 500 ? false:true"
            >
              <v-icon>{{changeUI ? 'view_list':'view_module'}}</v-icon>
            </v-btn>              
            </v-list-item-action> -->
            </v-list-item>
          </v-list>

          <v-card
            class="elevation-0"
            :color="color.BG"
            id="thiscontainer_myfile"
          >
            <v-card-text class="pa-0">
              <v-container fluid>
                <v-layout row wrap fill-height justify-center>
                  <!-- chip countfile/folder search-->
                  <v-flex lg12 class="pa-4">
                    <div v-if="resolutionScreen >= 500" row wrap fill-height>
                      <!-- <v-layout>
                      <v-flex lg2 class="pl-2">
                        <v-select
                        v-model="selectedFruits"
                        :items="itemssearchtype"
                        label="sort"
                        outlined
                        multiple
                        >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @click="toggle"
                            v-for="(item, i) in listsortby"
                            :key="i"
                          >
                            <v-list-item-content>
                              <v-list-item-title >
                                {{listsortby.sort_by_}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                  
                        </v-select>
                      </v-flex>
                      </v-layout> -->
                      <v-card class="elevation-0">
                        <v-layout row wrap fill-height justify-center>
                          <v-flex xs12 sm12 md12 lg12 class="pt-6">
                            <v-container fluid>
                              <!-- <v-layout>
                                   <v-flex xs12 lg12 class="pa-2">เงื่อนไขการค้นหา</v-flex>
                              </v-layout> -->
                              <v-layout>
                                <!-- ช่องเลือก วันก่อนหมดอายุหรือวันหมดอายุ -->
                                <v-flex xs12 lg3 class="pl-2">
                                  <v-select
                                    outlined
                                    dense
                                    v-model="search_type"
                                    prepend-icon="mdi-table-headers-eye"
                                    :label="
                                      $t('default') === 'th' ? 'ระบุหัวข้อ' : 'Specify topic'
                                    "
                                    :items="
                                      $t('default') === 'th'
                                        ? itemssearchtype_th
                                        : itemssearchtype
                                    "
                                  ></v-select>
                                </v-flex>
                                <!-- ช่องเลือก sort by -->
                                <v-flex xs12 lg3 class="pl-2 text-left">
                                  <v-select
                                    outlined
                                    dense
                                    v-model="sortby"
                                    prepend-icon="mdi-sort"
                                    clearable
                                    :label="
                                      $t('default') === 'th'
                                        ? 'เรียงตาม'
                                        : 'Sort by'
                                    "
                                    :items="
                                      $t('default') === 'th'
                                        ? itemssortby_th
                                        : itemssortby
                                    "
                                    :disabled="search_type === ''"
                                    @change="fn_sortbyandsearch()"
                                  ></v-select>
                                </v-flex>
                                <!-- ช่องกรอก วันที่หมดอายุ from -->
                                <v-flex xs12 lg3 class="pl-2">
                                  <v-dialog
                                    ref="dialogFromdatedocument"
                                    v-model="modalFromdatedocument"
                                    :return-value.sync="fromdatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :disabled="search_type === ''"
                                        :color="color.theme"
                                        :label="
                                          search_type === 'วันหมดอายุ'
                                            ? 'วันที่หมดอายุ'
                                            : search_type === 'วันก่อนหมดอายุ'
                                            ? 'วันที่ก่อนหมดอายุ'
                                            : search_type === 'Expiration time'
                                            ? 'Expiration time to'
                                            : search_type ===
                                              'Warning expiration time'
                                            ? 'Warning expiration time to'
                                            : $t(
                                                'myinboxPage.conditionOptional.from_datedocument'
                                              )
                                        "
                                        v-model="fromdatedocument"
                                        @click:prepend="
                                          modalFromdatedocument = true
                                        "
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fromdatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      :max="datenow"
                                      @input="changeFromdatedocument()"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="
                                          modalFromdatedocument = false
                                        "
                                        >{{
                                          $t(
                                            "myinboxPage.conditionOptional.closefromdatedocument"
                                          )
                                        }}</v-btn
                                      >
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- ช่องกรอก วันที่หมดอายุ to -->
                                <v-flex xs12 lg3>
                                  <v-dialog
                                    ref="dialogTodatedocument"
                                    v-model="modalTodatedocument"
                                    :return-value.sync="todatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :disabled="search_type === ''"
                                        readonly
                                        :color="color.theme"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.to_datedocument'
                                          )
                                        "
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="
                                          modalTodatedocument = true
                                        "
                                        v-on="on"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="
                                        $refs.dialogTodatedocument.save(
                                          todatedocument
                                        )
                                      "
                                      :min="fromdatedocument"
                                      :max="datenow"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="
                                          modalTodatedocument = false
                                        "
                                        >{{
                                          $t(
                                            "myinboxPage.conditionOptional.closetodatedocument"
                                          )
                                        }}</v-btn
                                      >
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- ปุ่มค้นหาวันที่ก่อนหมดอายุ หมดอายุ -->
                                <v-flex xs2 lg3 class="pl-1 pr-1 text-left">
                                  <v-btn
                                    block
                                    :color="color.theme"
                                    :text-color="color.chipText"
                                    class="white--text"
                                    :disabled="search_type === ''"
                                    @click="fn_sortbyandsearch()"
                                    >{{
                                      $t("statusfileexpired.expired_search")
                                    }}</v-btn
                                  >
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-card>
                      <!-- Countfiles&Folders -->
                      <v-flex xs12 lg12 class="text-right">
                        <br />
                        <!-- New Countfiles by scrolling -->
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>folder</v-icon>
                          {{ totaldata["totalfolder"] }}
                          {{
                            totaldata["totalfolder"] > 1
                              ? $t("folders")
                              : $t("folder")
                          }}
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-file</v-icon>
                          {{ totaldata["totalfile"] }}
                          {{
                            totaldata["totalfile"] > 1
                              ? $t("files")
                              : $t("file")
                          }}
                        </v-chip>
                      </v-flex>
                    </div>
                    <!-- หน้าจอxs -->
                    <div v-else>
                      <v-card class="elevation-0" id="borderradius">
                        <v-layout row wrap fill-height justify-center>
                          <v-flex xs12 sm12 md12 lg12 class="px-8">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <!-- ช่องเลือก วันก่อนหมดอายุหรือวันหมดอายุ -->
                                <v-flex xs12 lg3>
                                  <p class="mb-2">{{ $t('default') === 'th' ? 'หัวข้อ' : 'Topic' }}</p>
                                  <v-select
                                    outlined
                                    dense
                                    v-model="search_type"
                                    :label="
                                      $t('default') === 'th' ? 'ระบุหัวข้อ' : 'Specify topic'
                                    "
                                    :items="
                                      $t('default') === 'th'
                                        ? itemssearchtype_th
                                        : itemssearchtype
                                    "
                                  ></v-select>
                                </v-flex>
                                <!-- ช่องเลือก sort by -->
                                <v-flex xs12 lg3>
                                  <p class="mb-2">{{ $t('default') === 'th' ? 'เรียงตาม' : 'Sort by' }}</p>
                                  <v-select
                                    outlined
                                    dense
                                    v-model="sortby"
                                    clearable
                                    :label="
                                      $t('default') === 'th'
                                        ? 'เรียงตาม'
                                        : 'Sort by'
                                    "
                                    :items="
                                      $t('default') === 'th'
                                        ? itemssortby_th
                                        : itemssortby
                                    "
                                    :disabled="search_type === ''"
                                    @change="fn_sortbyandsearch()"
                                  ></v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 class="mb-4">
                                  <div>{{ $t('toolbar.searching.startenddate') }}</div>
                                </v-flex>
                                <!-- ช่องกรอก วันที่หมดอายุ from -->
                                <v-flex xs12 sm5>
                                  <v-dialog
                                    ref="dialogFromdatedocument"
                                    v-model="modalFromdatedocument"
                                    :return-value.sync="fromdatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :disabled="search_type === ''"
                                        :color="color.theme"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.from_datedocument'
                                          )
                                        "
                                        v-model="fromdatedocument"
                                        @click:prepend="
                                          modalFromdatedocument = true
                                        "
                                        append-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fromdatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      :max="datenow"
                                      @input="changeFromdatedocument()"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="
                                          modalFromdatedocument = false
                                        "
                                        >{{
                                          $t(
                                            "myinboxPage.conditionOptional.closefromdatedocument"
                                          )
                                        }}</v-btn
                                      >
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <v-flex sm1 mt-2 d-flex justify-center v-if="resolutionScreen == 400">-</v-flex>
                                <!-- ช่องกรอก วันที่หมดอายุ to -->
                                <v-flex xs12 sm5>
                                  <v-dialog
                                    ref="dialogTodatedocument"
                                    v-model="modalTodatedocument"
                                    :return-value.sync="todatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :disabled="search_type === ''"
                                        readonly
                                        :color="color.theme"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.to_datedocument'
                                          )
                                        "
                                        v-model="todatedocument"
                                        append-icon="event"
                                        @click:prepend="
                                          modalTodatedocument = true
                                        "
                                        v-on="on"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="
                                        $refs.dialogTodatedocument.save(
                                          todatedocument
                                        )
                                      "
                                      :min="fromdatedocument"
                                      :max="datenow"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="
                                          modalTodatedocument = false
                                        "
                                        >{{
                                          $t(
                                            "myinboxPage.conditionOptional.closetodatedocument"
                                          )
                                        }}</v-btn
                                      >
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <!-- ปุ่มค้นหาวันที่ก่อนหมดอายุ หมดอายุ -->
                                <v-btn
                                  :color="color.theme"
                                  :text-color="color.chipText"
                                  class="white--text"
                                  :disabled="search_type === ''"
                                  @click="fn_sortbyandsearch()"
                                  block
                                >
                                  {{ $t("statusfileexpired.expired_search") }}
                                </v-btn>
                              </v-layout>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-card>

                      <v-flex xs12 sm12 lg12 class="text-right mt-4">
                        <!-- New Countfiles by scrolling -->
                        <v-chip
                          class="ma-2"
                          color="#1876D1"
                          :text-color="color.chipText"
                        >
                          <v-icon left>folder</v-icon>
                          {{ totaldata["totalfolder"] }}
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          color="#5AB685"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-file</v-icon>
                          {{ totaldata["totalfile"] }}
                        </v-chip>
                      </v-flex>
                    </div>
                  </v-flex>
                  <!-- ตาราง -->
                  <v-flex xs12 lg12 class="pa-4">
                    <!-- หน้าจอธรรมดา -->
                    <div
                      v-if="
                        $vuetify.breakpoint.name === 'xl' ||
                          $vuetify.breakpoint.name === 'lg' ||
                          $vuetify.breakpoint.name === 'md'
                      "
                    >
                      <div>
                        <v-data-table
                          v-model="filemultipledownload"
                          :headers="headers"
                          :items="rootfile"
                          item-key="file_id"
                          :single-select="false"
                          :search="search"
                          class="elevation-0 drag-selector pa-1"
                          :show-select="statusmutipledownload"
                          item-selected
                          :no-data-text="$t('tablefile.empty')"
                          :items-per-page="rootfile.length"
                          :hide-default-footer="true"
                        >
                          <!-- <template v-slot:top>
                                <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                            </template>-->
                          <template v-slot:[`header.file_type`]="{ header }">
                            <span :style="headerTable">{{ header.text }}</span>
                          </template>
                          <template v-slot:[`header.file_name`]="{ header }">
                            <span
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            >
                            <!-- คลิก Sort File จากหัวข้อ -->
                            <!-- <span
                              class="pointer"
                              @click="
                                (multiSort = !multiSort),
                                  (sortby = 'name'),
                                  (clickSort = true),
                                  sortFile(rootfile)
                              "
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            > -->
                          </template>
                          <template v-slot:[`header.file_owner`]="{ header }">
                            <span
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            >
                            <!-- คลิก Sort File จากหัวข้อ -->
                            <!-- <span
                              class="pointer"
                              @click="
                                (multiSort = !multiSort),
                                  (sortby = 'owner'),
                                  (clickSort = true),
                                  sortFile(rootfile)
                              "
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            > -->
                          </template>
                          <!-- <template v-slot:header.file_lastdtm="{ header }">
                          <span
                            class="pointer"
                            @click="(multiSort = !multiSort), (sortby = 'date'), (clickSort = true), sortFile(rootfile)"
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template> -->
                          <template v-slot:[`header.file_size`]="{ header }">
                            <span
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            >
                            <!-- คลิก Sort File จากหัวข้อ -->
                            <!-- <span
                              class="pointer"
                              @click="
                                (multiSort = !multiSort),
                                  (sortby = 'size'),
                                  (clickSort = true),
                                  sortFile(rootfile)
                              "
                              :style="headerTable"
                              >{{ $t(header.text) }}</span
                            > -->
                          </template>
                          <template
                            v-slot:[`header.warning_expiration_time`]="{
                              header,
                            }"
                          >
                            <span :style="headerTable">{{
                              $t(header.text)
                            }}</span>
                          </template>
                          <template
                            v-slot:[`header.expiration_time`]="{ header }"
                          >
                            <span :style="headerTable">{{
                              $t(header.text)
                            }}</span>
                          </template>

                          <template v-slot:[`header.file_status`]="{ header }">
                            <span :style="headerTable">{{
                              $t(header.text)
                            }}</span>
                          </template>
                          <!-- --------------------------------------------------------------------------------------------------------------------------------- -->
                          <template v-slot:item="props">
                            <tr
                              :style="selectItemTable(props.item.file_id, 'table') + 'cursor: pointer;'"
                              @contextmenu="
                                rightclickfileandfolder($event, props.item ,rootfile)
                              "
                              @dblclick="
                                gotodirectory(props.item , rootfile)
                              "
                              @click="setSelectitemtable(props.item)"
                            >
                              <td
                                class="text-center"
                                v-show="statusmutipledownload"
                              >
                                <v-checkbox
                                  v-model="filemultipledownload"
                                  :value="props.item"
                                />
                              </td>
                              <td
                                width="7%"
                                class="text-center"
                                style="padding:0px;margin:0px;"
                              >
                                <v-badge
                                  color="orange"
                                  overlap
                                  icon="link"
                                  v-if="
                                    props.item.file_status_sharelink === 'Y' ||
                                      props.item.file_status_sharelink === 'P'
                                  "
                                >
                                  <v-icon
                                    large
                                    :color="props.item.file_icon[1]"
                                    >{{ props.item.file_icon[0] }}</v-icon
                                  >
                                </v-badge>
                                <v-badge color="red" overlap icon="lock" v-else-if="props.item.editing_status === 'Y'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>
                              <v-badge color="red" overlap icon="mdi-sync-circle" v-else-if="props.item.editing_status === 'S'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>
                                <v-icon
                                  v-else
                                  large
                                  :color="props.item.file_icon[1]"
                                  >{{ props.item.file_icon[0] }}</v-icon
                                >
                                <div>
                                  <v-chip
                                    x-small
                                    dark
                                    v-show="
                                      props.item.status_expired === '0' ||
                                        props.item.status_expired === '1' ||
                                        props.item.status_expired === '4'
                                    "
                                    :color="
                                      props.item.status_expired === '0'
                                        ? 'red'
                                        : props.item.status_expired === '1' ||
                                          props.item.status_expired === '4'
                                        ? '#fc9208e6'
                                        : 'white'
                                    "
                                    >{{
                                      props.item.status_expired === "0"
                                        ? $t("statusfileexpired.expired")
                                        : props.item.status_expired === "1" ||
                                          props.item.status_expired === "4"
                                        ? $t("statusfileexpired.nearexpired")
                                        : ""
                                    }}</v-chip
                                  >
                                </div>
                              </td>

                              <td
                                width="40%"
                                :title="props.item.file_name"
                                style="padding:0px 16px;margin:0px;"
                              >
                                <div
                                  style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis; width:460px"
                                >
                                  {{ props.item.file_name }}
                                  <div v-show="props.item.editing_status === 'Y'">
                                <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                  >{{ props.item.last_update_name.name_th }} กำลังแก้ไข</v-chip
                                >
                                <v-chip x-small color="primary" v-else
                                  >{{ props.item.last_update_name.name_eng }} editting</v-chip
                                >
                              </div>
                              <div v-show="props.item.editing_status === 'S'">
                                <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                  >กำลังซิงค์...</v-chip
                                >
                                <v-chip x-small color="primary" v-else
                                  >Syncing...</v-chip
                                >
                              </div>
                                </div>

                              </td>

                              <td
                                width="10%"
                                class="text-center"
                                style="padding:0px 16px;margin:0px;"
                                v-if="$t('default') === 'th'"
                              >
                                <div style="justify-content: center;">
                                  {{ props.item.file_owner_th }}
                                  <v-icon
                                    small
                                    color="orange"
                                    v-if="props.item.file_status_share === 'Y'"
                                    >group_add</v-icon
                                  >
                                </div>
                              </td>
                              <td
                                width="10%"
                                class="text-center"
                                style="padding:0px 16px;margin:0px;"
                                v-else
                              >
                                <div style="justify-content: center;">
                                  {{ props.item.file_owner_eng }}
                                  <v-icon
                                    small
                                    color="orange"
                                    v-if="props.item.file_status_share === 'Y'"
                                    >group_add</v-icon
                                  >
                                </div>
                              </td>
                              <!-- <td width="15%" style="padding:0px;margin:0px;">
                              {{ formatdatetime(props.item.file_lastdtm) }}
                            </td> -->
                              <td
                                width="10%"
                                class="text-center"
                                style="padding:0px 16px;margin:0px;"
                              >
                                {{ calculatesize(props.item.file_size) }}
                              </td>
                              <td
                                width="10%"
                                class="text-center"
                                style="padding:0px 16px;margin:0px;"
                              >
                                {{
                                  showdatetime(
                                    props.item.warning_expiration_time
                                  )
                                }}
                              </td>
                              <td
                                width="10%"
                                class="text-center"
                                style="padding:0px 16px;margin:0px;"
                              >
                                {{ showdatetime(props.item.expiration_time) }}
                              </td>
                              <td
                                width="10%"
                                class="text-center"
                                v-if="props.item.file_status === 'M'"
                                style="padding:0px 16px;margin:0px;"
                              ></td>

                              <td
                                width="10%"
                                v-else
                                class="text-center"
                                style="padding:0px 16px;margin:0px;"
                              >
                                <v-btn
                                  class="elevation-0"
                                  fab
                                  small
                                  outlined
                                  @click="
                                    rightclickfileandfolder($event, props.item ,rootfile)
                                  "
                                >
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </div>
                    </div>
                    <!-- หน้าจอมือถือ -->
                    <div v-else class="ma-2">
                      <v-list
                        two-line
                        class="pb-2 elevation-0"
                        v-if="rootfile.length > 0" 
                        id="borderradius"
                      >
                        <template v-for="(item, index) in rootfile">
                          <v-list-item
                            :key="index.title"
                            @contextmenu="rightclickfileandfolder($event, item ,rootfile)"
                            @dblclick="
                              gotodirectory(
                                item,rootfile
                              )
                            "
                            style="cursor: pointer;"
                          >
                            <v-list-item-action>
                              <v-badge
                                color="orange"
                                overlap
                                icon="link"
                                v-if="
                                  item.file_status_sharelink === 'Y' ||
                                    item.file_status_sharelink === 'P'
                                "
                              >
                                <v-icon large :color="item.file_icon[1]">{{
                                  item.file_icon[0]
                                }}</v-icon>
                              </v-badge>
                              <v-badge
                              color="red"
                              overlap
                              icon="lock"
                              v-else-if="item.editing_status === 'Y'">
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-badge
                              color="red"
                              overlap
                              icon="mdi-sync-circle"
                              v-else-if="item.editing_status === 'S'">
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                              <v-icon v-else large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                              <!-- <v-chip
                                      x-small
                                      dark
                                      v-if="props.item.status_expired !== '3'&& props.item.status_expired !== undefined && props.item.status_expired !== '' && props.item.status_expired !== null"
                                      :color="props.item.status_expired === '0' ? 'red':props.item.status_expired === '1' ? '#fc9208e6':props.item.status_expired === '2' ? '#fc9208e6':'white'"
                                    >{{props.item.status_expired === '0' ? 'หมดอายุ': props.item.status_expired === '1' ? 'ใกล้หมดอายุ':props.item.status_expired === '2' ? 'ใกล้หมดอายุ':''}}</v-chip> -->
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.file_name"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-text="calculatesize(item.file_size)"
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle v-show="item.editing_status === 'Y'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'">{{ item.last_update_name.name_th }} กำลังแก้ไข</v-chip>
                              <v-chip x-small color="primary" v-else>{{ item.last_update_name.name_eng }} editting</v-chip>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-show="item.editing_status === 'S'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'"> กำลังซิงค์...</v-chip>
                              <v-chip x-small color="primary" v-else>Syncing...</v-chip>
                            </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn
                                class="elevation-0"
                                fab
                                text
                                small                                
                                @click.stop="
                                  rightclickfileandfolder($event, item, rootfile)
                                "
                                :color="color.theme"
                              >
                                <v-icon>mdi-dots-horizontal</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider
                            v-if="index < rootfile.length -1"
                            :key="index.title"
                            class="mx-4"
                            style="border-color: #F5F5F5;"
                          ></v-divider>
                        </template>
                      </v-list>
                      <v-list
                        v-if="
                          rootfile.length === 0 &&
                            disablescrolling === true &&
                            loaddataprogress === false
                        "
                        class="elevation-0"
                        id="borderradius"
                      >
                        <v-list-item>
                          <v-list-item-content>
                            <v-img
                              :src="require('../assets/img/icon_empty_data.png')"
                              max-width="132"
                              max-height="150"
                              class="mx-auto"
                            />
                            <v-list-item-title
                              :style="'font-size: 16px; font-weight: 700; line-height: 24px; color:' + color.theme + ';'"
                              class="text-center mt-8"
                              v-text="$t('tablefile.empty')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-flex>
                </v-layout>
                <scroll-loader
                  class="pa-0"
                  :loader-method="getfileandfolder"
                  :loader-disable="disablescrolling"
                >
                </scroll-loader>
                <div class="text-center" v-if="loaddataprogress === true">
                  <v-progress-circular
                    :size="50"
                    :color="color.theme"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-container>
              <expiredrightclick
                :show="showexpiredtMenu"
                @closecurrentonly="showexpiredtMenu = false"
                :AreaX="x"
                :AreaY="y"
                :filedata="currentfile"
                :arrayfile="rootfile"
                @reload="getfileandfolder_child()"
                @callupdatestatusfile="deletenewfunction"
                @callshowinfolder="fn_showinfolder"
              ></expiredrightclick>
              <previewfile
                :show="openpreviewfile"
                :arrayfile="arrayfile"
                :filedata_1="currentfile"
                :filedata="currentfile"
                :param_preview="param_preview"
                @closepreviewfile="(openpreviewfile = false), $emit('openDrag')"
                @openpreviewfile="openpreviewfile = true"
                @closeDrag="$emit('closeDrag')"
                @openDrag="$emit('openDrag')"
                @reload="getfileandfolder_child()"
              ></previewfile>
            </v-card-text>
          </v-card>
        </v-card>
      </v-content>
    </div>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import format from "date-fns/format";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import VueCookies from "vue-cookies";
import "swiper/css/swiper.css";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { slider, slideritem } from "vue-concise-slider";
import onebox_toolbar from "../components/layout/layout-toolbar-new";
const expiredrightclick = () =>
  import("../components/contextmenu/expiredfilerightclick");
const previewfile = () => import("../components/optional/dialog-preview");
// const previewfile = () => import("../optional/dialog-preview");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {
    onebox_toolbar,
    expiredrightclick,
    previewfile
  },
  data: function() {
    return {
      param_preview:"",
      file:"",
      openpreviewfile:false,
      arrayfile:[],
      selectedFruits: [],
      datenow: "",
      sortby: "",
      listsortby: [
        {
          sort_by_: "Ascending",
        },
        {
          sort_by_: "Descending",
        },
      ],
      itemssortby: ["Ascending", "Descending"],
      itemssortby_th: ["น้อยไปมาก", "มากไปน้อย"],
      search_type: "",
      itemssearchtype_th: ["วันหมดอายุ", "วันก่อนหมดอายุ"],
      itemssearchtype: ["Expiration time", "Warning expiration time"],
      fromdate_hint: "",
      todate_hint: "",
      modalFromdatedocument: false,
      fromdatedocument: "",
      modalTodatedocument: false,
      todatedocument: "",
      parentid_file: "",
      fileexpired: [],
      folderexpired: [],
      currentfile: "",
      disablescrolling: true,
      loaddataprogress: true,
      x: 0,
      y: 0,
      showexpiredtMenu: false,
      totaldata: { totalall: 0, totalfile: 0, totalfolder: 0 },
      // total_data: { _totalall: 0, _totalfile: 0, _totalfolder: 0 },
      checkedList: [],
      filemultipledownload: [],
      page: 1,
      size: 50,
      rootfile: [],
      buff: [],
      statusmutipledownload: false,
      search: "",
      changeUI: localStorage.getItem("changeStoreUI") === "true" ? true : false,
      arrayfileidselect: [],
      headers: [
        {
          text: "#",
          align: "center",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "28%",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          align: "center",
          value: "file_owner",
          width: "10%",
          sortable: false,
        },
        // {
        //   text: "tablefile.lastmodified",
        //   value: "file_lastdtm",
        //   width: "18%",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "10%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.warning_expiration_time",
          value: "warning_expiration_time",
          width: "15%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.expiration_time",
          value: "expiration_time",
          width: "15%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "10%",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
      "role_level",
      "feature_onlyoffice"
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        // Old
        let l = this.rootfile.length;

        // New api
        // let l = this.fileCount;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    showdatafilter() {
      // console.log(search);
      return this.rootfile.filter((item) => {
        return (
          item.file_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      // console.log(this.color.theme);

      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      //return "color:" + this.color.theme + ";";
      return "color:" + "white" + ";";
    },
  },
  methods: {
    gotodirectory(file,listfile_){
      this.currentfile = file;
      this.arrayfile = listfile_;
      let currentfile_type_file = file.file_name.split(".");
      if(file.type !== 'folder'){
        // let checkinet_jv = VueCookies.get("feature_onlyoffice")
        // if(checkinet_jv === 'false'){
        let checkinet_jv = this.feature_onlyoffice;
          if(checkinet_jv === false){
            this.param_preview = "preview";
            this.opennewpreviewfile = true;
          }else{
            if(
              // (['04', '05', '06'].includes(file.file_permission_2)) &&
              (file.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.file_type.toLowerCase()) || 
              file.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.data_type.toLowerCase()) || 
              currentfile_type_file[1] && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes( currentfile_type_file[1].toLowerCase()))
            ){
              if(this.currentfile.status_expired === '0'){
              this.param_preview = "editfileonline_expired";
            }else{
              this.param_preview = "editfileonline";
            }
            }else{
              this.param_preview = "preview";
            }
          }
        if(file.editing_status === 'S'){
            Toast.fire({
                icon: "warning",
                title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              });
          }else if(file.editing_status === 'Y'){
            if(file.last_update_name.name_th === 'ฉัน'){
              this.openpreviewfile = true;
            }else{
              Toast.fire({
                icon: "warning",
                title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              });
            }
          }else{
            this.openpreviewfile = true;
          }
      //  this.openpreviewfile = true;
      }
    },
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      this.todateupload = format(new Date(), "yyyy-MM-dd");
    },
    changeFromdatedocument() {
      this.$refs.dialogFromdatedocument.save(this.fromdatedocument);
      this.todatedocument = format(new Date(), "yyyy-MM-dd");
    },
    //แหล่งที่มา
    fn_showinfolder(parameter) {
      console.log("parameter", parameter);
      console.log("parameter.folder_id", parameter.folder_id);
      console.log(
        "this.dataAccountActive['directory_id']",
        this.dataAccountActive["directory_id"]
      );
      this.parentid_file = parameter.folder_id;
      console.log(this.parentid_file);
      if (this.parentid_file === this.dataAccountActive["directory_id"]) {
        this.$router.replace("/mydrive");
      } else {
        // if (this.parentidfile === "" || this.parentidfile === null || this.parentidfile === undefined) {
        //     this.$router.push({ path: "/directory/" + this.dataAccountActive["directory_id"] });
        // }else{
        this.$router.push({ path: "/directory/" + this.parentid_file });
        // }
      }
    },

    //set date time ไว้ที่ตาราง
    showdatetime(_datetime) {
      // console.log("_datetime", _datetime);
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];

        return dateyear + "-" + datemonth + "-" + dateday;
      }
    },

    //ลบไฟล์และโฟล์เดอร์
    async deletenewfunction(parameter) {
      if (
        // this.role_level === "True" ||
        // parameter.file_permission.delete === "True" ||
        // parameter.file_permission.admin_data === "True" ||
        // parameter.file_owner_eng === "Me"
        // เปลี่ยนมาเช็คอันนี้
        this.role_level === "True" ||
        parameter.file_permission_2 === "05" ||
        parameter.file_permission_2 === "06" ||
        parameter.file_owner_eng === "Me"
      ) {
        let payload = {
          account_id: parameter.account_sender,
          user_id: this.dataUsername,
          data_id: parameter.file_id,
          data_type: parameter.file_type === "folder" ? "folder" : "file",
          status_data: "T",
        };
        let auth = await gbfGenerate.generateToken();
        console.log("payload", payload);
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES +
              "/api/remove_file_folder_to_trash",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              console.log("delresponse", response);
              Toast.fire({
                icon: "success",
                title:
                  this.$t("toast.text.remove") +
                  parameter.file_name +
                  this.$t("toast.text.totrash"),
              });

              this.getfileandfolder_child();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          });
      } else {
        Toast.fire({
          icon: "error",
          title: "สิทธิ์ของคุณไม่สามารถลบไฟล์ได้",
        });
      }
    },

    //check คลิกขวา
    rightclickfileandfolder(e, file, rootfile) {
       // เมื่อคลิกจุดสามจุด ให้โฟกัสรายการที่เลือก
       if (!this.arrayfileidselect.includes(file.file_id)) {
        this.arrayfileidselect = [file.file_id]
      }
      e.preventDefault();
      // this.sendParentfolder();
      this.showexpiredtMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.$nextTick(() => {
        this.currentfile = file;
        this.arrayfile= rootfile;
        console.log(" this.arrayfile",this.arrayfile);
        console.log("this.currentfile", this.currentfile);
        this.showexpiredtMenu = true;
        if (this.showMenu === true) {
          this.showexpiredtMenu = false;
        } else {
          setTimeout(() => {
            this.showexpiredtMenu = true;
          }, 300);
        }
      });
    },

    //show icon ตามเงื่อนไข
    vBadgeIcon(item) {
      let stringClass = "";
      if (item.file_status_sharelink === "Y") {
        stringClass = "link";
      } else {
        stringClass = "";
      }
      if (item.file_status_share === "Y") {
        if (stringClass == "") {
          stringClass = "group_add";
        } else {
          stringClass += " group_add";
        }
      }
      if (item.status_updating === "") {
        if (stringClass == "") {
          stringClass = "check";
        } else {
          stringClass += " check";
        }
      } else {
        stringClass += "";
      }
      return stringClass;
    },

    selectColor(e, type) {
      if (type === "table") {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      } else {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      }      
      // if (type === "card") {
      //   if (this.filemultipledownload.length < 1) {
      //   } else {
      //     for (let i = 0; i < this.filemultipledownload.length; i++) {
      //       if (e === this.filemultipledownload[i].file_name) {
      //         return "#D6EAF8";
      //       }
      //     }
      //   }
      // } else {
      //   if (this.filemultipledownload.length < 1) {
      //   } else {
      //     for (let i = 0; i < this.filemultipledownload.length; i++) {
      //       if (e === this.filemultipledownload[i].file_name) {
      //         return "color:#2471A3 ";
      //       }
      //     }
      //   }
      // }
    },

    // ตั้ง icon file/folder
    seticonNew(type, raw_parameter) {
      // console.log(parameter);

      let dataicon;
      let parameter;
      if (typeof raw_parameter === "string") {
        parameter = raw_parameter.toLowerCase();
      } else {
        parameter = raw_parameter;
      }
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      if (type == "folder") {
        dataicon = ["folder", "#FDD361"];
      } else {
        if (parameter === "xlsx" || parameter === "xls" || parameter === "xlsb") {
          dataicon = ["mdi-file-excel-outline", "green"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint-outline", "orange"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word-outline", "primary"];
        } else if (parameter === "html") {
          dataicon = ["mdi-language-html5", "#FF603F"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box-outline", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf-outline", "red"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code-outline", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text-outline", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png"
        ) {
          dataicon = ["mdi-file-image-outline", "blue-grey"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link-outline", "pink"];
        } else {
          // dataicon = ["mdi-file-question-outline", "black"];
          dataicon = ["mdi-file-outline", "black"];
        }
      }

      return dataicon;
    },

    // ตั้ง icon file/folder
    seticon_New(type, parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (type == "folder") {
        dataicon = [
          "folder",
          // "blue"
          "#FDD361",
        ];
      } else {
        if (parameter === "xlsx" || parameter === "xls" || parameter === "xlsb") {
          dataicon = ["mdi-file-excel", "green"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint", "orange"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word", "primary"];
        } else if (parameter === "html") {
          dataicon = ["mdi-language-html5", "#FF603F"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf", "red"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png"
        ) {
          dataicon = ["mdi-file-image", "blue-grey"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link", "pink"];
        } else {
          dataicon = ["mdi-file", "black"];
        }
      }

      return dataicon;
    },
    //ขนาดไฟล์
    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },

    //formatdatetime ไว้ส่งค่าไปที่ api
    // formatdatetime(_datetime) {
    //   if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
    //     return "-";
    //   } else {
    //     let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
    //     let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
    //     let dateday = _datetime.split("")[6] + _datetime.split("")[7];
    //     let hour = _datetime.split("")[8] + _datetime.split("")[9];
    //     let minute = _datetime.split("")[10] + _datetime.split("")[11];
    //     let second = _datetime.split("")[12] + _datetime.split("")[13];

    //     return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
    //   }
    // },

    //check เพ่อนเปลี่ยนหน้า UI
    changeLocalStorage() {
      if (localStorage.getItem("changeStoreUI") === "true") {
        localStorage.setItem("changeStoreUI", "false");
        this.changeUI = false;
      } else if (localStorage.getItem("changeStoreUI") === "false") {
        localStorage.setItem("changeStoreUI", "true");
        this.changeUI = true;
      } else {
        console.log("localStore", localStorage.getItem("changeStoreUI"));
        localStorage.removeItem("changeStoreUI");
      }
    },

    //เรียงข้อมูล
    async fn_sortbyandsearch() {
      // if(this.sortby !== '' && this.fromdatedocument !== '' && this.todatedocument !== ''){

      let type_search;
      let sort_by;
      if (this.search_type === "Expiration time") {
        type_search = "expiration_time";
      } else if (this.search_type === "Warning expiration time") {
        type_search = "warning_expiration_time";
      } else if (this.search_type === "วันหมดอายุ") {
        type_search = "expiration_time";
      } else if (this.search_type === "วันก่อนหมดอายุ") {
        type_search = "warning_expiration_time";
      }
      // else{
      //   type_search = 'expiration_time';
      // }

      if (this.sortby === "Ascending") {
        sort_by = "asc";
      } else if (this.sortby === "Descending") {
        sort_by = "desc";
      } else if (this.sortby === "น้อยไปมาก") {
        sort_by = "asc";
      } else if (this.sortby === "มากไปน้อย") {
        sort_by = "desc";
      }
      // else{
      //   sort_by = 'asc';
      // }
      // this.rootfile = []
      let payload;
      this.disablescrolling = true;
      this.loaddataprogress = true;
      this.page = 1;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);

      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }

      if (this.dataAccountActive["type"] === "Business") {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.dataAccountActive.business_info.my_folder_id,
          status_file: "EXP",
          status_folder: "EXP",
          limit: cal_limit,
          offset: cal_offset,
          search_type: type_search,
          order_by: sort_by,
          exp_from_dtm: this.fromdatedocument,
          exp_to_dtm: this.todatedocument,
        };
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/select_files_and_folder/expiration_time",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            this.loaddataprogress = false;
            if (response.data.status === "OK") {
              console.log("response", response);
              for (let i = 0; i < response.data.data.length; i++) {
                let dataFileAndFolder = {};
                let typefile = response.data.data[i].name.split(".");
                let type = response.data.data[i].data_type;
                if (response.data.data[i].data_type === "folder") {
                  this.folderexpired.push(dataFileAndFolder);
                } else {
                  this.fileexpired.push(dataFileAndFolder);
                }
                dataFileAndFolder["account_reciever"] = "";
                dataFileAndFolder["account_sender"] = this.dataAccountId;
                dataFileAndFolder["account_id"] =
                  response.data.data[i].account_id;
                dataFileAndFolder["countnum"] = response.data.data[i].countnum;
                dataFileAndFolder["file_createdtm"] =
                  response.data.data[i].cre_dtm;
                dataFileAndFolder["folder_id"] =
                  response.data.data[i].folder_id;
                dataFileAndFolder["file_id"] = response.data.data[i].id;
                dataFileAndFolder["file_lastdtm"] =
                  response.data.data[i].last_dtm;
                dataFileAndFolder["file_linkshare"] =
                  response.data.data[i].link;
                dataFileAndFolder["file_name"] = response.data.data[i].name;
                dataFileAndFolder["file_owner_eng"] =
                  response.data.data[i].name_eng;
                dataFileAndFolder["file_owner_th"] =
                  response.data.data[i].name_th;
                dataFileAndFolder["file_permission"] =
                  response.data.data[i].permission_account;
                dataFileAndFolder["file_permission_2"] =
                  response.data.data[i].permission_account_v2;
                dataFileAndFolder["permission_department_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["permission_business_setting"] =
                  response.data.data[i].permission_business_setting;
                dataFileAndFolder["permission_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["priority"] = response.data.data[i].priority;
                dataFileAndFolder["file_size"] = response.data.data[i].size;
                dataFileAndFolder["file_status"] = response.data.data[i].status;
                dataFileAndFolder["file_status_share"] =
                  response.data.data[i].status_share;
                dataFileAndFolder["file_status_sharelink"] =
                  response.data.data[i].status_share_link;
                dataFileAndFolder["system"] = response.data.data[i].system;
                dataFileAndFolder["type"] = response.data.data[i].data_type;
                dataFileAndFolder["file_icon"] = this.seticon_New(
                  type,
                  typefile[typefile.length - 1]
                );
                dataFileAndFolder["file_type"] =
                  type == "folder" ? "folder" : typefile[typefile.length - 1];
                dataFileAndFolder["user_id"] = response.data.data[i].user_id;
                dataFileAndFolder["status_lock_folder"] =
                  response.data.data[i]["status_lock_folder"];
                dataFileAndFolder["status_lock_file"] =
                  response.data.data[i]["status_lock_file"];
                dataFileAndFolder["status_expired"] =
                  response.data.data[i]["status_expired"];
                dataFileAndFolder["expiration_time"] =
                  response.data.data[i]["expiration_time"];
                dataFileAndFolder["warning_expiration_time"] =
                  response.data.data[i]["warning_expiration_time"];
                dataFileAndFolder["full_path"] =
                  response.data.data[i]["full_path"];
                dataFileAndFolder["full_path_id"] =
                  response.data.data[i]["full_path_id"];
                this.rootfile.push(dataFileAndFolder);
                dataFileAndFolder["editing_status"] =
                  response.data.data[i]["editing_status"];
                dataFileAndFolder["last_update_name"] =
                  response.data.data[i]["last_update_name"];

              }

              console.log("roottttt_3333", this.rootfile);
              this.totaldata = {
                totalall: response.data.count,
                totalfile: response.data.count_file,
                totalfolder: response.data.count_folder,
              };
              // this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
              // this.aspectData = [
              //   {
              //     aspect: response.data.your_folder[0]["aspect"],
              //     aspect_key: response.data.your_folder[0]["aspect_key"],
              //   },
              // ];
              this.disablescrolling =
                this.totaldata["totalall"] <= response.data.count;

              // console.log("Newrootfile", this.newrootfile);
            } else {
              this.disablescrolling = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loaddataprogress = false;
            this.disablescrolling = true;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          });
      } else {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: "",
          status_file: "EXP",
          status_folder: "EXP",
          limit: cal_limit,
          offset: cal_offset,
          search_type: type_search,
          order_by: sort_by,
          exp_from_dtm: this.fromdatedocument,
          exp_to_dtm: this.todatedocument,
        };
        console.log(payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/select_files_and_folder/expiration_time",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            this.loaddataprogress = false;
            if (response.data.status === "OK") {
              console.log("response", response);
              for (let i = 0; i < response.data.data.length; i++) {
                let dataFileAndFolder = {};
                let typefile = response.data.data[i].name.split(".");
                let type = response.data.data[i].data_type;
                if (response.data.data[i].data_type === "folder") {
                  this.folderexpired.push(dataFileAndFolder);
                } else {
                  this.fileexpired.push(dataFileAndFolder);
                }
                dataFileAndFolder["account_reciever"] = "";
                dataFileAndFolder["account_sender"] = this.dataAccountId;
                dataFileAndFolder["account_id"] =
                  response.data.data[i].account_id;
                dataFileAndFolder["countnum"] = response.data.data[i].countnum;
                dataFileAndFolder["file_createdtm"] =
                  response.data.data[i].cre_dtm;
                dataFileAndFolder["folder_id"] =
                  response.data.data[i].folder_id;
                dataFileAndFolder["file_id"] = response.data.data[i].id;
                dataFileAndFolder["file_lastdtm"] =
                  response.data.data[i].last_dtm;
                dataFileAndFolder["file_linkshare"] =
                  response.data.data[i].link;
                dataFileAndFolder["file_name"] = response.data.data[i].name;
                dataFileAndFolder["file_owner_eng"] =
                  response.data.data[i].name_eng;
                dataFileAndFolder["file_owner_th"] =
                  response.data.data[i].name_th;
                dataFileAndFolder["file_permission"] =
                  response.data.data[i].permission_account;
                dataFileAndFolder["file_permission_2"] =
                  response.data.data[i].permission_account_v2;
                dataFileAndFolder["permission_department_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["permission_business_setting"] =
                  response.data.data[i].permission_business_setting;
                dataFileAndFolder["permission_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["priority"] = response.data.data[i].priority;
                dataFileAndFolder["file_size"] = response.data.data[i].size;
                dataFileAndFolder["file_status"] = response.data.data[i].status;
                dataFileAndFolder["file_status_share"] =
                  response.data.data[i].status_share;
                dataFileAndFolder["file_status_sharelink"] =
                  response.data.data[i].status_share_link;
                dataFileAndFolder["system"] = response.data.data[i].system;
                dataFileAndFolder["type"] = response.data.data[i].data_type;
                dataFileAndFolder["file_icon"] = this.seticon_New(
                  type,
                  typefile[typefile.length - 1]
                );
                dataFileAndFolder["file_type"] =
                  type == "folder" ? "folder" : typefile[typefile.length - 1];
                dataFileAndFolder["user_id"] = response.data.data[i].user_id;
                dataFileAndFolder["status_lock_folder"] =
                  response.data.data[i]["status_lock_folder"];
                dataFileAndFolder["status_lock_file"] =
                  response.data.data[i]["status_lock_file"];
                dataFileAndFolder["status_expired"] =
                  response.data.data[i]["status_expired"];
                dataFileAndFolder["expiration_time"] =
                  response.data.data[i]["expiration_time"];
                dataFileAndFolder["warning_expiration_time"] =
                  response.data.data[i]["warning_expiration_time"];
                dataFileAndFolder["full_path"] =
                  response.data.data[i]["full_path"];
                dataFileAndFolder["full_path_id"] =
                  response.data.data[i]["full_path_id"];
                this.rootfile.push(dataFileAndFolder);
                dataFileAndFolder["editing_status"] =
                  response.data.data[i]["editing_status"];
                dataFileAndFolder["last_update_name"] =
                  response.data.data[i]["last_update_name"];
              }
              console.log("roottttt", this.rootfile);
              this.totaldata = {
                totalall: response.data.count,
                totalfile: response.data.count_file,
                totalfolder: response.data.count_folder,
              };
              // this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
              // this.aspectData = [
              //   {
              //     aspect: response.data.your_folder[0]["aspect"],
              //     aspect_key: response.data.your_folder[0]["aspect_key"],
              //   },
              // ];
              this.disablescrolling =
                this.totaldata["totalall"] <= response.data.count;

              // console.log("Newrootfile", this.newrootfile);
            } else {
              this.disablescrolling = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loaddataprogress = false;
            this.disablescrolling = true;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          });
      }
      //  }
    },

    // ดึงข้อมูลโฟลเดอร์และไฟล์ แบบ Scroll โดยจะดึงแบบ limit offset /status_expired = 0
    async getfileandfolder() {
      let payload;
      this.disablescrolling = true;
      this.loaddataprogress = true;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }
      if (this.dataAccountActive["type"] === "Business") {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.dataAccountActive.business_info.my_folder_id,
          status_file: "EXP",
          status_folder: "EXP",
          limit: cal_limit,
          offset: cal_offset,
          search_type: "",
          order_by: "",
          exp_from_dtm: "",
          exp_to_dtm: "",
        };
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/select_files_and_folder/expiration_time",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            this.loaddataprogress = false;
            if (response.data.status === "OK") {
              console.log(response);
              console.log("reponseok", response.data.data);
              for (let i = 0; i < response.data.data.length; i++) {
                let dataFileAndFolder = {};
                let typefile = response.data.data[i].name.split(".");
                let type = response.data.data[i].data_type;
                if (response.data.data[i].data_type === "folder") {
                  this.folderexpired.push(dataFileAndFolder);
                } else {
                  this.fileexpired.push(dataFileAndFolder);
                }
                dataFileAndFolder["account_reciever"] = "";
                dataFileAndFolder["account_sender"] = this.dataAccountId;
                dataFileAndFolder["account_id"] =
                  response.data.data[i].account_id;
                dataFileAndFolder["countnum"] = response.data.data[i].countnum;
                dataFileAndFolder["file_createdtm"] =
                  response.data.data[i].cre_dtm;
                dataFileAndFolder["folder_id"] =
                  response.data.data[i].folder_id;
                dataFileAndFolder["file_id"] = response.data.data[i].id;
                dataFileAndFolder["file_lastdtm"] =
                  response.data.data[i].last_dtm;
                dataFileAndFolder["file_linkshare"] =
                  response.data.data[i].link;
                dataFileAndFolder["file_name"] = response.data.data[i].name;
                dataFileAndFolder["file_owner_eng"] =
                  response.data.data[i].name_eng;
                dataFileAndFolder["file_owner_th"] =
                  response.data.data[i].name_th;
                dataFileAndFolder["file_permission"] =
                  response.data.data[i].permission_account;
                dataFileAndFolder["file_permission_2"] =
                  response.data.data[i].permission_account_v2;
                dataFileAndFolder["permission_department_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["permission_business_setting"] =
                  response.data.data[i].permission_business_setting;
                dataFileAndFolder["permission_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["priority"] = response.data.data[i].priority;
                dataFileAndFolder["file_size"] = response.data.data[i].size;
                dataFileAndFolder["file_status"] = response.data.data[i].status;
                dataFileAndFolder["file_status_share"] =
                  response.data.data[i].status_share;
                dataFileAndFolder["file_status_sharelink"] =
                  response.data.data[i].status_share_link;
                dataFileAndFolder["system"] = response.data.data[i].system;
                dataFileAndFolder["type"] = response.data.data[i].data_type;
                dataFileAndFolder["file_icon"] = this.seticon_New(
                  type,
                  typefile[typefile.length - 1]
                );
                dataFileAndFolder["file_type"] =
                  type == "folder" ? "folder" : typefile[typefile.length - 1];
                dataFileAndFolder["user_id"] = response.data.data[i].user_id;
                dataFileAndFolder["status_lock_folder"] =
                  response.data.data[i]["status_lock_folder"];
                dataFileAndFolder["status_lock_file"] =
                  response.data.data[i]["status_lock_file"];
                dataFileAndFolder["status_expired"] =
                  response.data.data[i]["status_expired"];
                dataFileAndFolder["expiration_time"] =
                  response.data.data[i]["expiration_time"];
                dataFileAndFolder["warning_expiration_time"] =
                  response.data.data[i]["warning_expiration_time"];
                dataFileAndFolder["full_path"] =
                  response.data.data[i]["full_path"];
                dataFileAndFolder["full_path_id"] =
                  response.data.data[i]["full_path_id"];
                dataFileAndFolder["status_updating"] =
                  response.data.data[i]["status_updating"];
                this.rootfile.push(dataFileAndFolder);
                dataFileAndFolder["editing_status"] =
                  response.data.data[i]["editing_status"];
                dataFileAndFolder["last_update_name"] =
                  response.data.data[i]["last_update_name"];
              }

              console.log("rootttttmain", this.rootfile);
              console.log("cfolder", this.folderexpired);
              console.log("cfile", this.fileexpired);
              this.totaldata = {
                totalall: response.data.count,
                totalfile: response.data.count_file,
                totalfolder: response.data.count_folder,
              };
              // this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
              // this.aspectData = [
              //   {
              //     aspect: response.data.your_folder[0]["aspect"],
              //     aspect_key: response.data.your_folder[0]["aspect_key"],
              //   },
              // ];
              this.disablescrolling =
                this.totaldata["totalall"] <= response.data.count;

              // console.log("Newrootfile", this.newrootfile);
            } else {
              this.disablescrolling = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loaddataprogress = false;
            this.disablescrolling = true;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          });
      } else {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: "",
          status_file: "EXP",
          status_folder: "EXP",
          limit: cal_limit,
          offset: cal_offset,
        };

        console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/select_files_and_folder/expiration_time",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            this.loaddataprogress = false;
            if (response.data.status === "OK") {
              console.log(response);
              console.log("reponseok", response.data.data);
              for (let i = 0; i < response.data.data.length; i++) {
                let dataFileAndFolder = {};
                let typefile = response.data.data[i].name.split(".");
                let type = response.data.data[i].data_type;
                if (response.data.data[i].data_type === "folder") {
                  this.folderexpired.push(dataFileAndFolder);
                } else {
                  this.fileexpired.push(dataFileAndFolder);
                }
                dataFileAndFolder["account_reciever"] = "";
                dataFileAndFolder["account_sender"] = this.dataAccountId;
                dataFileAndFolder["account_id"] =
                  response.data.data[i].account_id;
                dataFileAndFolder["countnum"] = response.data.data[i].countnum;
                dataFileAndFolder["file_createdtm"] =
                  response.data.data[i].cre_dtm;
                dataFileAndFolder["folder_id"] =
                  response.data.data[i].folder_id;
                dataFileAndFolder["file_id"] = response.data.data[i].id;
                dataFileAndFolder["file_lastdtm"] =
                  response.data.data[i].last_dtm;
                dataFileAndFolder["file_linkshare"] =
                  response.data.data[i].link;
                dataFileAndFolder["file_name"] = response.data.data[i].name;
                dataFileAndFolder["file_owner_eng"] =
                  response.data.data[i].name_eng;
                dataFileAndFolder["file_owner_th"] =
                  response.data.data[i].name_th;
                dataFileAndFolder["file_permission"] =
                  response.data.data[i].permission_account;
                dataFileAndFolder["file_permission_2"] =
                  response.data.data[i].permission_account_v2;
                dataFileAndFolder["permission_department_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["permission_business_setting"] =
                  response.data.data[i].permission_business_setting;
                dataFileAndFolder["permission_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["priority"] = response.data.data[i].priority;
                dataFileAndFolder["file_size"] = response.data.data[i].size;
                dataFileAndFolder["file_status"] = response.data.data[i].status;
                dataFileAndFolder["file_status_share"] =
                  response.data.data[i].status_share;
                dataFileAndFolder["file_status_sharelink"] =
                  response.data.data[i].status_share_link;
                dataFileAndFolder["system"] = response.data.data[i].system;
                dataFileAndFolder["type"] = response.data.data[i].data_type;
                dataFileAndFolder["file_icon"] = this.seticon_New(
                  type,
                  typefile[typefile.length - 1]
                );
                dataFileAndFolder["file_type"] =
                  type == "folder" ? "folder" : typefile[typefile.length - 1];
                dataFileAndFolder["user_id"] = response.data.data[i].user_id;
                dataFileAndFolder["status_lock_folder"] =
                  response.data.data[i]["status_lock_folder"];
                dataFileAndFolder["status_lock_file"] =
                  response.data.data[i]["status_lock_file"];
                dataFileAndFolder["status_expired"] =
                  response.data.data[i]["status_expired"];
                dataFileAndFolder["expiration_time"] =
                  response.data.data[i]["expiration_time"];
                dataFileAndFolder["warning_expiration_time"] =
                  response.data.data[i]["warning_expiration_time"];
                dataFileAndFolder["full_path"] =
                  response.data.data[i]["full_path"];
                dataFileAndFolder["full_path_id"] =
                  response.data.data[i]["full_path_id"];
                dataFileAndFolder["status_updating"] =
                  response.data.data[i]["status_updating"];
                  dataFileAndFolder["editing_status"] =
                  response.data.data[i]["editing_status"];
                dataFileAndFolder["last_update_name"] =
                  response.data.data[i]["last_update_name"];

                this.rootfile.push(dataFileAndFolder);
              }

              console.log("roottttt", this.rootfile);
              console.log("cfolder", this.folderexpired);
              console.log("cfile", this.fileexpired);
              this.totaldata = {
                totalall: response.data.count,
                totalfile: response.data.count_file,
                totalfolder: response.data.count_folder,
              };
              // this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
              // this.aspectData = [
              //   {
              //     aspect: response.data.your_folder[0]["aspect"],
              //     aspect_key: response.data.your_folder[0]["aspect_key"],
              //   },
              // ];
              this.disablescrolling =
                this.totaldata["totalall"] <= response.data.count;

              // console.log("Newrootfile", this.newrootfile);
            } else {
              this.disablescrolling = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loaddataprogress = false;
            this.disablescrolling = true;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          });
      }
    },

    // ดึงข้อมูลโฟลเดอร์และไฟล์ ในกรณีที่ต้องการดึงใหม่ตั้งแต่ต้น ใช้สำหรับให้ child component เรียกข้อมูล
    async getfileandfolder_child() {
      // this.rootfile = []
      let payload;
      this.disablescrolling = true;
      this.loaddataprogress = true;
      this.page = 1;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);

      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }

      if (this.dataAccountActive["type"] === "Business") {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.dataAccountActive.business_info.my_folder_id,
          status_file: "EXP",
          status_folder: "EXP",
          limit: cal_limit,
          offset: cal_offset,
          search_type: "",
          order_by: "",
          exp_from_dtm: "",
          exp_to_dtm: "",
        };
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/select_files_and_folder/expiration_time",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            this.loaddataprogress = false;
            if (response.data.status === "OK") {
              console.log("response", response);
              for (let i = 0; i < response.data.data.length; i++) {
                let dataFileAndFolder = {};
                let typefile = response.data.data[i].name.split(".");
                let type = response.data.data[i].data_type;
                if (response.data.data[i].data_type === "folder") {
                  this.folderexpired.push(dataFileAndFolder);
                } else {
                  this.fileexpired.push(dataFileAndFolder);
                }
                dataFileAndFolder["account_reciever"] = "";
                dataFileAndFolder["account_sender"] = this.dataAccountId;
                dataFileAndFolder["account_id"] =
                  response.data.data[i].account_id;
                dataFileAndFolder["countnum"] = response.data.data[i].countnum;
                dataFileAndFolder["file_createdtm"] =
                  response.data.data[i].cre_dtm;
                dataFileAndFolder["folder_id"] =
                  response.data.data[i].folder_id;
                dataFileAndFolder["file_id"] = response.data.data[i].id;
                dataFileAndFolder["file_lastdtm"] =
                  response.data.data[i].last_dtm;
                dataFileAndFolder["file_linkshare"] =
                  response.data.data[i].link;
                dataFileAndFolder["file_name"] = response.data.data[i].name;
                dataFileAndFolder["file_owner_eng"] =
                  response.data.data[i].name_eng;
                dataFileAndFolder["file_owner_th"] =
                  response.data.data[i].name_th;
                dataFileAndFolder["file_permission"] =
                  response.data.data[i].permission_account;
                dataFileAndFolder["file_permission_2"] =
                  response.data.data[i].permission_account_v2;
                dataFileAndFolder["permission_department_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["permission_business_setting"] =
                  response.data.data[i].permission_business_setting;
                dataFileAndFolder["permission_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["priority"] = response.data.data[i].priority;
                dataFileAndFolder["file_size"] = response.data.data[i].size;
                dataFileAndFolder["file_status"] = response.data.data[i].status;
                dataFileAndFolder["file_status_share"] =
                  response.data.data[i].status_share;
                dataFileAndFolder["file_status_sharelink"] =
                  response.data.data[i].status_share_link;
                dataFileAndFolder["system"] = response.data.data[i].system;
                dataFileAndFolder["type"] = response.data.data[i].data_type;
                dataFileAndFolder["file_icon"] = this.seticon_New(
                  type,
                  typefile[typefile.length - 1]
                );
                dataFileAndFolder["file_type"] =
                  type == "folder" ? "folder" : typefile[typefile.length - 1];
                dataFileAndFolder["user_id"] = response.data.data[i].user_id;
                dataFileAndFolder["status_lock_folder"] =
                  response.data.data[i]["status_lock_folder"];
                dataFileAndFolder["status_lock_file"] =
                  response.data.data[i]["status_lock_file"];
                dataFileAndFolder["status_expired"] =
                  response.data.data[i]["status_expired"];
                dataFileAndFolder["expiration_time"] =
                  response.data.data[i]["expiration_time"];
                dataFileAndFolder["warning_expiration_time"] =
                  response.data.data[i]["warning_expiration_time"];
                dataFileAndFolder["full_path"] =
                  response.data.data[i]["full_path"];
                dataFileAndFolder["full_path_id"] =
                  response.data.data[i]["full_path_id"];
                dataFileAndFolder["status_updating"] =
                  response.data.data[i]["status_updating"];
                  dataFileAndFolder["editing_status"] =
                  response.data.data[i]["editing_status"];
                dataFileAndFolder["last_update_name"] =
                  response.data.data[i]["last_update_name"];
                
                this.rootfile.push(dataFileAndFolder);
              }

              console.log("roottttt_3333", this.rootfile);
              this.totaldata = {
                totalall: response.data.count,
                totalfile: response.data.count_file,
                totalfolder: response.data.count_folder,
              };
              // this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
              // this.aspectData = [
              //   {
              //     aspect: response.data.your_folder[0]["aspect"],
              //     aspect_key: response.data.your_folder[0]["aspect_key"],
              //   },
              // ];
              this.disablescrolling =
                this.totaldata["totalall"] <= response.data.count;

              // console.log("Newrootfile", this.newrootfile);
            } else {
              this.disablescrolling = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loaddataprogress = false;
            this.disablescrolling = true;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          });
      } else {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: "",
          status_file: "EXP",
          status_folder: "EXP",
          limit: cal_limit,
          offset: cal_offset,
        };
        console.log(payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/select_files_and_folder/expiration_time",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            this.loaddataprogress = false;
            if (response.data.status === "OK") {
              console.log("response", response);
              for (let i = 0; i < response.data.data.length; i++) {
                let dataFileAndFolder = {};
                let typefile = response.data.data[i].name.split(".");
                let type = response.data.data[i].data_type;
                if (response.data.data[i].data_type === "folder") {
                  this.folderexpired.push(dataFileAndFolder);
                } else {
                  this.fileexpired.push(dataFileAndFolder);
                }
                dataFileAndFolder["account_reciever"] = "";
                dataFileAndFolder["account_sender"] = this.dataAccountId;
                dataFileAndFolder["account_id"] =
                  response.data.data[i].account_id;
                dataFileAndFolder["countnum"] = response.data.data[i].countnum;
                dataFileAndFolder["file_createdtm"] =
                  response.data.data[i].cre_dtm;
                dataFileAndFolder["folder_id"] =
                  response.data.data[i].folder_id;
                dataFileAndFolder["file_id"] = response.data.data[i].id;
                dataFileAndFolder["file_lastdtm"] =
                  response.data.data[i].last_dtm;
                dataFileAndFolder["file_linkshare"] =
                  response.data.data[i].link;
                dataFileAndFolder["file_name"] = response.data.data[i].name;
                dataFileAndFolder["file_owner_eng"] =
                  response.data.data[i].name_eng;
                dataFileAndFolder["file_owner_th"] =
                  response.data.data[i].name_th;
                dataFileAndFolder["file_permission"] =
                  response.data.data[i].permission_account;
                dataFileAndFolder["file_permission_2"] =
                  response.data.data[i].permission_account_v2;
                dataFileAndFolder["permission_business_setting"] =
                  response.data.data[i].permission_business_setting;
                dataFileAndFolder["permission_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["permission_department_setting"] =
                  response.data.data[i].permission_setting;
                dataFileAndFolder["priority"] = response.data.data[i].priority;
                dataFileAndFolder["file_size"] = response.data.data[i].size;
                dataFileAndFolder["file_status"] = response.data.data[i].status;
                dataFileAndFolder["file_status_share"] =
                  response.data.data[i].status_share;
                dataFileAndFolder["file_status_sharelink"] =
                  response.data.data[i].status_share_link;
                dataFileAndFolder["system"] = response.data.data[i].system;
                dataFileAndFolder["type"] = response.data.data[i].data_type;
                dataFileAndFolder["file_icon"] = this.seticon_New(
                  type,
                  typefile[typefile.length - 1]
                );
                dataFileAndFolder["file_type"] =
                  type == "folder" ? "folder" : typefile[typefile.length - 1];
                dataFileAndFolder["user_id"] = response.data.data[i].user_id;
                dataFileAndFolder["status_lock_folder"] =
                  response.data.data[i]["status_lock_folder"];
                dataFileAndFolder["status_lock_file"] =
                  response.data.data[i]["status_lock_file"];
                dataFileAndFolder["status_expired"] =
                  response.data.data[i]["status_expired"];
                dataFileAndFolder["expiration_time"] =
                  response.data.data[i]["expiration_time"];
                dataFileAndFolder["warning_expiration_time"] =
                  response.data.data[i]["warning_expiration_time"];
                dataFileAndFolder["full_path"] =
                  response.data.data[i]["full_path"];
                dataFileAndFolder["full_path_id"] =
                  response.data.data[i]["full_path_id"];
                dataFileAndFolder["status_updating"] =
                  response.data.data[i]["status_updating"];
                  dataFileAndFolder["editing_status"] =
                  response.data.data[i]["editing_status"];
                dataFileAndFolder["last_update_name"] =
                  response.data.data[i]["last_update_name"];

                this.rootfile.push(dataFileAndFolder);
              }
              console.log("roottttt", this.rootfile);
              this.totaldata = {
                totalall: response.data.count,
                totalfile: response.data.count_file,
                totalfolder: response.data.count_folder,
              };
              // this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
              // this.aspectData = [
              //   {
              //     aspect: response.data.your_folder[0]["aspect"],
              //     aspect_key: response.data.your_folder[0]["aspect_key"],
              //   },
              // ];
              this.disablescrolling =
                this.totaldata["totalall"] <= response.data.count;

              // console.log("Newrootfile", this.newrootfile);
            } else {
              this.disablescrolling = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loaddataprogress = false;
            this.disablescrolling = true;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          });
      }
    },
    selectItemTable(item) {
      return this.selectColor(item, "table")
        ? "background-color:#D6EAF8;"
        : "opacity: unset;";
    },
    setSelectitemtable(file) {
      console.log('file',file)
      this.arrayfileidselect = [];
      if (file.file_id) {
        this.arrayfileidselect = [file.file_id]
      }
    },
  },
  mounted() {
    this.datenow = format(new Date(), "yyyy-MM-dd");
    this.loaddataprogress = false;
    // this.getfileandfolder();
    this.disablescrolling = false;
  },
};
</script>
<style>
.drag-selector {
  padding: 15px;
}
.drag-selector__item {
  align-items: center;
}
template td {
  padding: 0px;
  margin: 0px;
}
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#demo {
  width: 100%;
  height: 100%;
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Sarabun, system-ui, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}

#thiscontainer_myfile {
  height: calc(101vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}

.v-progress-circular {
  margin: 1rem;
}
#slideritem:hover {
  background-color: #d6eaf8;
  border-radius: 5px;
}
#borderradius {
  border-radius: 15px;
}
</style>
